// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setModalState, modalViewsConst } from "../store/modalSlice";
import { setFlyLocation } from "../store/mapStateSlice";
import { ReactComponent as DotsVertical } from "../assets/icons/dots-vertical.svg";
import { ReactComponent as HomeSmile } from "../assets/icons/home-smile.svg";
import { AssetTag } from "./TagStyles";
import { schemaMatch } from "../util/utils";
import { assetSchema } from "../store/assetsSlice";

export const AssetRow = ({ asset }: AssetRowProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingsRef = useRef();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [hoveringSetting, setHoveringSetting] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setSettingsOpen(false); // Clicked outside the div
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingsRef]);

  const handleDelete = (e) => {
    console.log("HANDLE DELETE");
    setSettingsOpen(false);
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.deleteAssetModal,
        properties: asset,
      })
    );
    e.stopPropagation();
  };

  const handleEdit = (e) => {
    setSettingsOpen(false);
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editAssetNEW,
        properties: schemaMatch(asset, assetSchema),
      })
    );
    e.stopPropagation();
  };

  const handleCurveEdit = (e) => {
    setSettingsOpen(false);
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.assetPowerCurveEdit,
        properties: asset,
      })
    );
    e.stopPropagation();
  };

  const handleSettingsClick = (e) => {
    setSettingsOpen(!settingsOpen);
    e.stopPropagation();
  };

  return (
    <tr
      onClick={() => {
        navigate(`/home/group/${asset.assetGroupId}/asset/${asset.id}`);
        dispatch(setFlyLocation([asset.lng, asset.lat]));
      }}
      className={`cursor-pointer ${
        hoveringSetting ? "" : " hover:bg-slate-200"
      } 'bg-white'  border-y border-gray-200 `}
    >
      <td className="flex flex-row px-6 py-4 items-center">
        <div className="flex w-10 h-10 bg-gray-100 rounded-[20px] justify-center items-center">
          <HomeSmile className="h-6 w-6" />
        </div>
        <div className="text-gray-900 text-md font-semibold font-Inter ml-3 ">
          {asset.name}
        </div>
      </td>
      <td
        colSpan={3}
        className="px-6 py-4 text-md font-normal text-gray-600 hidden md:table-cell"
      >
        {asset.description}
      </td>
      {/* <td className="px-6 py-4 text-md font-normal text-gray-600 hidden md:table-cell">
        <AssetTag tagText={"demo"} />
      </td> */}
      {/* <td className="px-6 py-4 text-md font-normal text-gray-600 hidden md:table-cell">
        Default
      </td>
      <td className="px-6 py-4 text-md font-normal text-green-600 hidden md:table-cell">
        Clear
      </td> */}
      <td
        onClick={handleSettingsClick}
        ref={settingsRef}
        className="justify-around py-4 text-md font-normal text-gray-600 table-cell"
      >
        {settingsOpen && (
        <div
          style={{ transform: "translateX(-85px) translateY(-125px)" }}
          className={` absolute rounded bg-white border group-hover:bg-white hover:border-gray-200 `}
        >
            <div
              className="w-[170px]"
              onMouseEnter={() => setHoveringSetting(true)}
              onMouseLeave={() => setHoveringSetting(false)}
            >
              <div
                onClick={handleDelete}
                className="p-2 border border-white hover:bg-slate-200 "
              >
                Delete
              </div>
              <div
                onClick={handleEdit}
                className="p-2 border border-white hover:bg-slate-200 "
              >
                Edit Asset
              </div>
              <div
                onClick={handleCurveEdit}
                className="p-2 border border-white hover:bg-slate-200 "
              >
                Energy Model
              </div>
            </div>
        </div>
          )}
        <div className="w-full flex justify-center">
          <DotsVertical className="h-6 w-6 " />
        </div>
      </td>
    </tr>
  );
};

export const AssetTable = ({ assetItems }) => {
  return (
    <>
      <div className="w-full bg-white overflow-x-auto border border-gray-200 rounded-md">
        <table style={{ borderRadius: "10px" }} className="w-full text-left">
          <thead className="w-full bg-white text-gray-600 text-sm font-medium font-Inter">
            <tr>
              <th className="px-6 py-5">Name</th>
              <th colSpan={3} className="px-6 py-5 hidden md:table-cell">
                Description
              </th>
              {/* <th className="px-6 py-5 hidden md:table-cell">Tag</th> */}
              {/* <th className="px-6 py-5 hidden md:table-cell">IDF Curve</th>
              <th className="px-6 py-5 hidden md:table-cell">Status</th> */}
              <th className="px-6 py-5 text-center">Edit</th>
            </tr>
          </thead>
          <tbody>
            {assetItems.map((asset) => (
              <AssetRow key={asset.id} asset={asset} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
