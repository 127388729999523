// @ts-nocheck
import React, { useEffect, useState } from "react";
import { list, downloadData } from "aws-amplify/storage";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy-icon.svg";
import { ReactComponent as LineChartIcon } from "../../assets/icons/line-chart.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-02.svg";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../store/modalSlice";
import { record } from "aws-amplify/analytics";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import "@aws-amplify/ui-react/styles.css";

const dateShortRegex = /\b\d{4}-\d{2}-\d{2}-\d{2}\b/; //e.g. 2024-02-04-06

const baseURL = "https://2lq79cogme.execute-api.us-east-1.amazonaws.com/prod";

const ProcessModelDownload = ({ asset, assetOwner }) => {
  const dispatch = useDispatch();
  const [modelType, setModelType] = useState(
    asset?.processedModels?.items?.[0].routeShort ?? ""
  );
  const [modelEvalTimes, setModelEvalTimes] = useState([]);
  const [selectedEvalTime, setSelectedEvalTime] = useState("");
  const [storageImageRen, setStorageImageRen] = useState(null);
  const listProcessedModelsByType = async () => {
    try {
      const fileList = await list({
        path: ({ identityId }) => {
          if (assetOwner?.cognitoIdentityId) {
            return `private/${assetOwner?.cognitoIdentityId}/processedModels/${asset.id}/${modelType}/`;
          }
          return `private/${identityId}/processedModels/${asset.id}/${modelType}/`;
        },
      });
      const allFiles = [];
      fileList.items.forEach((fileData, i) => {
        const fileFolderList = fileData.path.split("/");
        const fileName = fileFolderList[fileFolderList.length - 1];
        if (dateShortRegex.test(fileName)) {
          const match = fileName.match(dateShortRegex);
          allFiles.push({ fileKey: fileData.path, dateShort: match[0] });
        }
      });
      setModelEvalTimes(
        allFiles.sort((a, b) => a.dateShort - b.dateShort).reverse()
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setModelEvalTimes([]);
    setSelectedEvalTime("");
    listProcessedModelsByType();
  }, [modelType]);

  const handleDownload = async () => {
    const dateShort = selectedEvalTime.match(dateShortRegex)[0];
    const downloadResult = await downloadData({
      path: selectedEvalTime,
    }).result;

    const jsonData = await downloadResult.body.text();
    const parsedData = JSON.parse(jsonData);
    const formattedData = {
      evalTime: dateShort,
      processedModel: parsedData,
    };

    const abbrname = asset.name.replaceAll(" ", "-");
    const fileName = `${dateShort}-processeddata-${abbrname}-${modelType}.json`;
    const fileContent = JSON.stringify(formattedData, null, 2); // Convert JSON object to a string with indentation

    record({
      name: "downloadProcessedModel",
      attributes: {
        assetName: asset.name,
        assetId: asset.id,
        time: dateShort,
        modelType,
      },
    });

    // Create a Blob with the JSON content
    const blob = new Blob([fileContent], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Set the file name for download
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  };

  const handleSetSelectedTime = (timeSelect) => {
    setSelectedEvalTime(timeSelect);
    const fileNameFolderArray = timeSelect.split("/");
    const fileName =
      fileNameFolderArray[fileNameFolderArray.length - 1].split(".")[0];
    // Craig Delete this after Explore Page
    // console.log("fileName", fileName)
    console.log(
      "PNG Route",
      `${fileNameFolderArray[0]}/${fileNameFolderArray[1]}/png/${fileNameFolderArray[3]}/${fileName}.png`
    );
    setStorageImageRen(
      `${fileNameFolderArray[0]}/${fileNameFolderArray[1]}/png/${fileNameFolderArray[3]}/${fileName}.png`
    );
  };

  return (
    <div className="">
      <div className="flex flow-row grow items-center border-b border-gray-200 p-2 ">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <LineChartIcon stroke="#A2BBBF" className="h-6 w-6 " />
        </div>
        <div
          className="flex grow text-gray-900 text-xl font-semibold font-Inter"
          style={{ textTransform: "capitalize" }}
        >
          Download Model Results
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <div className="p-2">
        <div
          name="name"
          className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
        >
          Name
        </div>
        <div className="mb-5 font-sans font-medium">{asset.name}</div>

        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          API Access
        </div>
        <div
          className={`flex justify-between h-12 ${
            selectedEvalTime ? "block" : "invisible"
          }`}
        >
          <div className="mb-5 font-sans font-medium ">
            {selectedEvalTime
              ? `${baseURL}/api/asset/${asset.id}/processed/${modelType}/${
                  selectedEvalTime === modelEvalTimes?.[0]?.fileKey
                    ? "latest"
                    : modelEvalTimes.find(
                        (item) => item.fileKey === selectedEvalTime
                      ).dateShort
                }`
              : ""}
          </div>
          <div className="pl-2">
            <button
              onClick={() => {
                const timeShort =
                  selectedEvalTime === modelEvalTimes?.[0]?.fileKey
                    ? "latest"
                    : modelEvalTimes.find(
                        (item) => item.fileKey === selectedEvalTime
                      ).dateShort;

                record({
                  name: "copyProcessedModelAPIroute",
                  attributes: {
                    assetName: asset.name,
                    assetId: asset.id,
                    time: timeShort,
                    modelType,
                  },
                });
                navigator.clipboard.writeText(
                  `${baseURL}/api/asset/${asset.id}/processed/${modelType}/${timeShort}`
                );
              }}
              className="border p-2 rounded hover:bg-slate-50"
            >
              <CopyIcon stroke="#A2BBBF" className="h-6 w-6 " />
            </button>
          </div>
        </div>
        {storageImageRen && (
          <div className="flex justify-center mb-6">
            <StorageImage
              style={{ height: "auto", width: "100%", maxWidth: "1500px" }}
              onGetUrlError={(err) => setStorageImageRen(null)}
              path={storageImageRen}
              validateObjectExistence={true}
            />
          </div>
        )}

        <div className="flex mb-5">
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Model Name
            </div>
            <select
              className="border p-2"
              value={modelType}
              onChange={(e) => setModelType(e.target.value)}
            >
              <option value="" disabled>
                Select Model Type
              </option>
              {asset.processedModels?.items && (
                <>
                  {asset.processedModels.items.map((processedModel) => {
                    return (
                      <option
                        key={processedModel.routeShort}
                        value={processedModel.routeShort}
                      >
                        {processedModel.label}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
          </div>

          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Date Issued
            </div>
            <select
              className="border p-2"
              id="dropdown"
              value={selectedEvalTime}
              onChange={(e) => handleSetSelectedTime(e.target.value)}
            >
              <option value="" disabled>
                Select Evaluation Time
              </option>
              {modelEvalTimes.map(({ dateShort, fileKey }) => {
                return (
                  <option key={dateShort} value={fileKey}>
                    {dateShort}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="flex flex-row grow space-x-3">
          <button
            className="flex-grow eai-btn my-1"
            onClick={() => dispatch(setModalOpen(false))}
          >
            <div className="mx-3">Cancel</div>
          </button>
          <button
            disabled={!selectedEvalTime}
            className={`flex-grow eai-btn-grn my-1  ${
              selectedEvalTime === "" ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            onClick={handleDownload}
          >
            <div className="mx-3">Download</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProcessModelDownload;
