// @ts-nocheck
import React, { useRef, useLayoutEffect, useState, useMemo } from "react";
import { dateFormatZone } from "./forecastUtil";

const TabularData = ({ data, yaxis, interpolatedData, timeZone }) => {
  const headers = useMemo(() => {
    // Create a Set to gather unique keys from all data records
    const allKeys = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(key => allKeys.add(key));
    });
  
    // Transform the keys into the desired header format
    return Array.from(allKeys).reduce((acc, curr) => {
      if (curr === "dateTime") {
        return { dateTime: "Date Time", ...acc };
      } else if (curr === "mean") {
        return { ...acc, mean: "Average" };
      } else {
        return { ...acc, [curr]: `${curr[0].toUpperCase()}${curr.slice(1)}` };
      }
    }, {});
  }, [data]);

  const topRef = useRef(null); // Ref for the top element
  const fillRef = useRef(null); // Ref for the filling rest of the space element
  const [fillHeight, setFillHeight] = useState(0);

  useLayoutEffect(() => {
    const updateHeight = () => {
      const topHeight = fillRef.current.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;
      const remainingHeight = viewportHeight - topHeight;

      setFillHeight(remainingHeight);
    };

    // Initial calculation
    updateHeight();

    // Recalculate on window resize
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const currentTimeClipped = (newTimeDate) => {
    // Create a Date object from the Zulu time string
    // const newTimeDate = new Date();
    // console.log("newTimeDate", newTimeDate)
    const { year, month, date, hour, minute, intTimeZone } = dateFormatZone(
      newTimeDate,
      timeZone
    );
    return `${date}-${month}-${year} ${hour}:${minute} ${intTimeZone}`;

    // const date = new Date();

    // // Get the year, month, day, hour, and minute
    // const year = date.getUTCFullYear();
    // const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    // const day = String(date.getUTCDate()).padStart(2, '0');
    // const hours = String(date.getUTCHours()).padStart(2, '0');
    // const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // // Construct a new Zulu time string without seconds and milliseconds
    // const clippedZuluTime = `${year}-${month}-${day}T${hours}:${minutes}Z`;

    // return clippedZuluTime;
  };

  const renderTable = () => {
    let currentTimeBorder = false;
    return data.map((entry, i) => {
      let border = "";
      if (i !== data.length - 1 && !currentTimeBorder) {
        const targetDate = new Date();
        const startDate = new Date(entry.dateTime);
        const endDate = new Date(data[i + 1].dateTime);
        if (targetDate >= startDate && targetDate <= endDate) {
          border += "border-b-4 border-b-red-500";
          currentTimeBorder = true;
        }
      }

      return (
        <tr
          className={`border-b ${border} border-gray-500 px-1 py-2 odd:bg-gray-200 even:bg-white`}
          key={entry.dateTime}
        >
          {Object.keys(headers).map((headerKeys) => {
            return (
              <td className="p-1" key={`${entry.dateTime}_${headerKeys}`}>
                {headerKeys === "dateTime"
                  ? currentTimeClipped(new Date(entry[headerKeys]))
                  : entry[headerKeys] ?? "-"}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div>
      <div className="flex justify-between mx-4">
        <div>
          <div className=" font-semibold">
            Current Time:{" "}
            <span className="font-medium">
              {currentTimeClipped(new Date())}
            </span>
          </div>
          <div className=" font-semibold">
            Units: <span className="font-medium">{yaxis}</span>
          </div>
        </div>
      </div>
      <div
        ref={topRef}
        style={{ height: `${fillHeight}px` }}
        className="scrollable-div rounded-t overflow-y-auto mx-4"
      >
        <table className="w-full border-x border-gray-500">
          <thead className="sticky top-0 border-b border-gray-500">
            <tr ref={fillRef} className="bg-eai-primary-accent text-white ">
              {Object.keys(headers).map((key, index) => {
                return (
                  <th className="p-2 text-left" key={key}>
                    {headers[key]}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="">
            {renderTable()}
            {/* {data.map((entry) => {
              return (
                <tr
                  className="border-b border-gray-500 px-1 py-2 odd:bg-gray-200 even:bg-white"
                  key={entry.dateTime}
                >
                  {Object.keys(headers).map((headerKeys) => {
                    return (
                      <td className="p-1" key={`${entry.dateTime}_${headerKeys}`}>
                        {entry[headerKeys]}
                      </td>
                    );
                  })}
                </tr>
              );
            })} */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabularData;
