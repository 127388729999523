// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ReactComponent as LightningIcon } from "../assets/icons/lightning.svg";
// import { uploadData } from "@aws-amplify/storage";
import { uploadData, downloadData } from "@aws-amplify/storage";
import { get, put, del, post } from "aws-amplify/api"
import {
  HeadBreadCrumb,
  NestedBreadCrumbs,
} from "../components/AssetBreadCrumb";
import { modalViewsConst, setModalState } from "../store/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchPCurves, fetchSolarEnergyModels } from "../store/pCurveSlice";
import { PCurveTable } from "../components/PerformanceCurve/PCurveTable";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { generateClient } from "aws-amplify/api";
import { createUploadData, updateUploadData } from "../graphql/mutations";
import Papa from "papaparse";

const UploadRecordSchema = z.object({
  name: z.string().trim().min(2).max(50),
  //   assetGroupId: z
  //     .string()
  //     .min(5, { message: "Asset must belong to Asset Group" }),
  description: z.string().trim().max(150),
  parameter: z.string().trim().max(150),
  objective: z.string().min(1, "Please select an objective"), // Dropdown 1
  optimizationHorizon: z
    .string()
    .min(1, "Please select an optimization horizon"), // Dropdown 2
  temporalResolution: z.string().min(1, "Please select a temporal resolution"), // Dropdown 3
  seasonality: z.string().min(1, "Please select seasonality option"), // Dropdown 4
});
type UploadRecordType = z.infer<typeof UploadRecordSchema>;

const CustomModelForm = ({ initialUploadForm, editFlag }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<UploadRecordType>({
    defaultValues: {
      name: initialUploadForm?.name || "",
      description: initialUploadForm?.description || "",
      parameter: initialUploadForm?.parameter || "",
      objective: initialUploadForm?.objective || "", // Initial value for new dropdown
      optimizationHorizon: initialUploadForm?.optimizationHorizon || "", // Initial value for new dropdown
      temporalResolution: initialUploadForm?.temporalResolution || "", // Initial value for new dropdown
      seasonality: initialUploadForm?.seasonality || "", // Initial value for new dropdown
    },
    resolver: zodResolver(UploadRecordSchema),
    mode: "onChange",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pCurve = useSelector((state) => state.pCurve);
  const [uploadNewFileFlag, setUploadeNewFileFlag] = useState(editFlag);
  const [csvData, setCsvData] = useState([]);
  const [file, setFile] = useState(null);
  const [objective, setObjective] = useState("");
  const [llmJustification, setLLMJustification] = useState("");
  const [loadingLLMflag, setLoadingLLMflag] = useState(false);

  const getS3csv = async () => {
    try {
      // Download the CSV file from S3 using `downloadData`
      const result = await downloadData({
        path: initialUploadForm.fileLocation,
      }).result;

      // Use FileReader to read the Blob content as text
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvText = event.target.result;
        parseCsv(csvText); // Parse the CSV content
      };

      // Read the file content as text (assuming result.Body is a Blob)
      reader.readAsText(result.body);
    } catch (error) {
      console.error("Error downloading and parsing CSV:", error);
    }
  };

  useEffect(() => {
    if (editFlag && initialUploadForm.fileLocation) {
      getS3csv();
    }
  }, []);

  const handleNewPCurve = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.energyModelSelect,
        properties: null,
      })
    );
  };

  const breadcrumbItems = [
    { route: "/home/dataupload", label: "Custom AI Models" },
    {
      route: "/home/dataupload/new",
      label: editFlag ? initialUploadForm?.name : "New Model",
    },
  ];

  const handleFileChange = (event) => {
    const selectedFile = event?.target?.files?.[0];
    if (!selectedFile) {
      return;
    }
    setFile(selectedFile);
    parseCsv(selectedFile);
  };

  // Function to parse CSV using PapaParse
  const parseCsv = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        setCsvData(result.data); // Set the parsed data to state
      },
      header: true, // Optional: Treat the first row as headers
      skipEmptyLines: true, // Skip empty lines
    });
  };

  // Corrected onSubmit function
  const onSubmit = async (formData: UploadRecordType) => {
    const client = generateClient();

    if (file) {
      try {
        // Generate a unique file name and upload to S3
        const fileName = `uploads/${file.name}`;

        const result = await uploadData({
          path: fileName,
          data: file,
        }).result;

        // Retrieve the file location URL after upload
        const fileLocation = result.path; // S3 key of the uploaded file

        // Include the file location in the form data
        formData.fileLocation = fileLocation;
      } catch (e) {
        console.log("Erropr", e);
      }
    }

    if (editFlag) {
      try {
        const { data } = await client.graphql({
          query: updateUploadData,
          variables: {
            input: {
              id: initialUploadForm.id,
              ...formData,
            },
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        const { data } = await client.graphql({
          query: createUploadData,
          variables: { input: formData },
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    navigate(`/home/dataupload`);
  };

  const onError = (errors: any) => {
    console.error("Validation Errors: ", errors); // Logs any validation errors on submit
  };

  const handleProcessObjective = async (e) => {
    
    setLoadingLLMflag(true);
    e.preventDefault();
  

      try {
        const restOperation = post({
          apiName: "llmapi",
          path: "/api/processedmodel",
          options: {
            body: {objective },
          },
        });
        const operationResponse = await restOperation.response;
        const { data } = await operationResponse.body.json();
        setLoadingLLMflag(false);
        console.log("Val", data)
        Object.keys(data).forEach((key) => {
          const theValue = data[key];
          if (key === "description") {
            setLLMJustification(theValue);
          } else {
            setValue(key, theValue);
          }
        });
      } catch (e) {
        console.log("e", e);
      }
  };

  return (
    <>
      <div className="flex-1 flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
          <NestedBreadCrumbs items={breadcrumbItems}>
            <LightningIcon
              stroke="#6B7280"
              className="w-5 h-5 ml-2 mr-1 cursor-pointer"
            />
          </NestedBreadCrumbs>
          <div className="flex grow p-5 h-[calc(100vh_-_9rem)] overflow-y-auto">
            <div className="w-full bg-white overflow-x-auto border border-gray-200 rounded-md flex">
              <div className="flex-1 p-4">
                <form
                  className="flex flex-col justify-between h-full "
                  onSubmit={handleSubmit(onSubmit, onError)}
                >
                  <div className="">
                    <h2 className="">Configure Your AI Weather Model</h2>
                    <br />
                    <div className="flex justify-between">
                      <div
                        name="name"
                        className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
                      >
                        Name
                      </div>
                      {errors.name ? (
                        <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                          {errors.name.message}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="flex grow">
                      <input
                        name="name"
                        {...register("name")}
                        className="flex grow border p-3 mb-5 text-md rounded-md"
                      />
                    </div>
                    <div className="flex justify-between">
                      <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                        Describe Weather Forecasting Problem e.g. "I want to
                        understand how wind will affect energy prices in New
                        York?"
                      </div>
                    </div>
                    <div className="flex grow">
                      <textarea
                        value={objective}
                        onChange={(e) => setObjective(e.target.value)}
                        className="flex grow border p-3 mb-2 text-md rounded-md"
                      />
                    </div>
                    <div className="mb-2 flex">
                      <button
                        disabled={objective.trim().length === 0}
                        className="eai-btn"
                        onClick={handleProcessObjective}
                      >
                        Configure Model
                      </button>
                      <div className="m-2">
                        {loadingLLMflag && "Loading..."}
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                        Model Description
                      </div>
                      {errors.description ? (
                        <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                          {errors.description.message}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="flex grow">
                      <textarea
                        name="description"
                        {...register("description")}
                        className="flex grow border p-3 mb-2 text-md rounded-md"
                      />
                    </div>

                    <div className="flex justify-between">
                      <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                        Parameter
                      </div>
                      {errors.paramter ? (
                        <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                          {/* {errors.description.message} */}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="flex grow">
                      <select
                        name="parameter"
                        {...register("parameter")}
                        className="flex grow border p-3 mb-5 text-md rounded-md"
                      >
                        <option value="">Select Parameter of Interest</option>

                        <option key={"Wind_Speed"} value={"Wind_Speed"}>
                          {"Wind Speed"}
                        </option>
                        <option key={"Wind_Direction"} value={"Wind_Direction"}>
                          {"Wind Direction"}
                          </option>
                        <option key={"Temperature"} value={"Temperature"}>
                          {"Temperature"}
                        </option>
                        <option key={"Precipitation"} value={"Precipitation"}>
                        {"Precipitation"}
                        </option>
                        <option key={"Humidity"} value={"Humidity"}>
                          {"Humidity"}
                        </option>
                        <option
                          key={"Solar_Irradiance_short"}
                          value={"Solar_Irradiance_short"}
                        >
                          {"Solar Irradiance Short Wave"}
                        </option>
                        <option
                          key={"Solar_Irradiance_long"}
                          value={"Solar_Irradiance_long"}
                        >
                          {"Solar Irradiance Long Wave"}
                        </option>

                        {/* Add more options as needed */}
                      </select>
                    </div>
                  </div>
                  <div className="flex gap-4 justify-between mb-4">
                    <div className="flex-1">
                      <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                        Objective
                      </div>
                      <select
                        name="objective"
                        {...register("objective")}
                        className="w-full border pl-3 py-3 mb-5 text-md rounded-md"
                      >
                        <option value="">Select Objective</option>
                        <option value="min_RMSE">Minimize RMSE</option>
                        <option value="min_MAE">Minimize MAE</option>
                        <option value="Match_Peaks">Match Peaks</option>
                      </select>
                      {errors.objective && (
                        <span className="text-red-500 text-sm mb-2">
                          {errors.objective.message}
                        </span>
                      )}
                    </div>

                    <div className="flex-1">
                      <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                        Optimization Horizon
                      </div>
                      <select
                        name="optimizationHorizon"
                        {...register("optimizationHorizon")}
                        className="w-full border pl-3 py-3 mb-5 text-md rounded-md"
                      >
                        <option value="">Select Horizon</option>
                        <option value="1_day">1 Day</option>
                        <option value="3_days">3 Days</option>
                        <option value="7_days">7 Days</option>  
                        <option value="1_month">1 Month</option>
                      </select>
                      {errors.optimizationHorizon && (
                        <span className="text-red-500 text-sm mb-2">
                          {errors.optimizationHorizon.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex gap-4 justify-between mb-4">
                    <div className="flex-1">
                      <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                        Temporal Resolution
                      </div>
                      <select
                        name="temporalResolution"
                        {...register("temporalResolution")}
                        className="w-full border pl-3 py-3 mb-5 text-md rounded-md"
                      >
                        <option value="">Select Resolution</option>
                        <option value="1_hour">1 Hour</option>
                        <option value="3_hours">3 Hours</option>
                        <option value="6_hours">6 Hours</option>
                        <option value="24_hours">24 Hours</option>
                      </select>
                      {errors.temporalResolution && (
                        <span className="text-red-500 text-sm mb-2">
                          {errors.temporalResolution.message}
                        </span>
                      )}
                    </div>

                    <div className="flex-1">
                      <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                        Seasonality
                      </div>
                      <select
                        name="seasonality"
                        {...register("seasonality")}
                        className="w-full border pl-3 py-3 mb-5 text-md rounded-md"
                      >
                        <option value="">Select Seasonality</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="annual">Annual</option>
                      </select>
                      {errors.seasonality && (
                        <span className="text-red-500 text-sm mb-2">
                          {errors.seasonality.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <div>File Upload</div>
                    {editFlag &&
                    initialUploadForm?.fileLocation &&
                    uploadNewFileFlag ? (
                      <div className="flex">
                        <button
                          onClick={() => setUploadeNewFileFlag(false)}
                          className="eai-btn m-2"
                        >
                          Change
                        </button>{" "}
                        <div>
                          {initialUploadForm?.fileLocation.split("/")[1]}
                        </div>
                      </div>
                    ) : (
                      <input
                        className="block p-2 w-full text-sm text-gray-500 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        type="file"
                        onChange={handleFileChange}
                      />
                    )}
                    <br />
                    <br />
                    <button type="submit" className="eai-btn">
                      {editFlag ? "Update Model" : "Create Model"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="flex-1 bg-gray-400 h-full overflow-y-auto">
              <div className="bg-gray-300 p-2">{llmJustification}</div>
                {csvData.length > 0 ? (
                  <div>
                  <div className="bg-gray-200 font-bold text-xl text-center ">
                    Upload Data
                  </div>
                  <table border="1" cellPadding="2" className="bg-gray-200 ">
                    <thead>
                      <tr>
                        {/* Render CSV headers */}
                        {Object.keys(csvData[0]).map((header, index) => (
                          <th key={header}>{header}</th>
                        ))}
                        </tr>
                          </thead>
                      <tbody>
                        {/* Render CSV rows */}
                        {csvData.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {Object.values(row).map((cell, cellIndex) => (
                              <td key={cellIndex}>{cell}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <h2 className="text-center">Preview</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModelForm;
