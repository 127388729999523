// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateClient } from "aws-amplify/api";
import {
  listGeoPoints,
  freqAnalysesByAssetId,
  geoPointsByAssetGroupId,
  getGeoPoint,
} from "../graphql/queries";
import {
  createGeoPoint,
  deleteGeoPoint,
  createFreqAnalysis,
  createFreqAnalysisStations,
  updateGeoPoint,
  createProcessedModel,
} from "../graphql/mutations";
import { schemaMatch } from "../util/utils";
import { minRainfallDataYear, maxRainfallDataYear } from "../util/constants";
import { geoPointsByAssetGroupIdCustom } from "../graphql/customQueries";
import {
  createGeoPointCustom,
  deleteGeoPointCustom,
  updateGeoPointCustom,
} from "../graphql/customMutations";

const client = generateClient();
export const assetSchema = [
  "name",
  "lat",
  "lng",
  "description",
  "assetGroupId",
  "value",
  "id",
  "elevation",
  "assetModels",
  "processedModels",
  "owner",
];

//CRAIG put mutation loading state
const initialState = {
  data: [],
  loading: "idle",
  error: null,
  hoveredAsset: null,
  selectedAsset: null,
  selectedAssetProximity: 0,
  stationYrlyCountFilter: 1,
  stationYearRange: [minRainfallDataYear, maxRainfallDataYear], //CRAIG CHANGE TO CONSTANTS
  proximityStations: [],
  filteredProximityStationIds: [],
  assetLayerVisible: true,
  freqAnalyses: {},
  dataGroups: {},
};

export const postProcessedModel = createAsyncThunk(
  "assets/postProcessedModel",
  async (input, thunkAPI) => {
    try {
      console.log("input", input);
      const { data } = await client.graphql({
        query: createProcessedModel,
        variables: { input },
      });
      console.log("data", data);

      return { data };
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAssetsByGroupId = createAsyncThunk(
  "assets/fetchAssetsByGroupId",
  async (assetGroupId, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: geoPointsByAssetGroupIdCustom,
        variables: {
          assetGroupId,
        },
      });

      return { assetGroupId, assets: data.geoPointsByAssetGroupId.items };
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAssetById = createAsyncThunk(
  "assets/fetchAssetById",
  async (id, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: getGeoPoint,
        variables: {
          input: { id },
        },
      });
      return data.getGeoPoint;
    } catch (error) {
      console.log("fetchAssetById error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAssets = createAsyncThunk(
  "assets/fetchAllUserAssets",
  async (_, thunkAPI) => {
    try {
      const { data } = await client.graphql({ query: listGeoPoints });
      return data.listGeoPoints.items;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const postAsset = createAsyncThunk(
  "assets/PostAsset",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: createGeoPointCustom,
        variables: { input },
      });
      return {
        assetGroupId: data.createGeoPoint.assetGroupId,
        asset: data.createGeoPoint,
      };
      // return data.createGeoPoint.assetGroupId;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "assets/DeleteAsset",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: deleteGeoPointCustom,
        variables: { input },
      });

      return {
        assetGroupId: data.deleteGeoPoint.assetGroupId,
        asset: data.deleteGeoPoint,
      };

      // return data.deleteGeoPoint;
    } catch (error) {
      console.log("DeleteError", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editAsset = createAsyncThunk(
  "assets/editAsset",
  async (input, thunkAPI) => {
    try {
      // const formattedInput = schemaMatch(input, alertSchema);
      const { data } = await client.graphql({
        query: updateGeoPointCustom,
        variables: { input },
      });
      return data.updateGeoPoint;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const postFreqAnalysis = createAsyncThunk(
  "assets/postFreqAnalysis",
  async ({ freqAnalysisMeta, filteredProximityStations }, thunkAPI) => {
    const filteredProximityStationsId = filteredProximityStations.map(
      (station) => station.stationID
    );
    try {
      const { data } = await client.graphql({
        query: createFreqAnalysis,
        variables: { input: freqAnalysisMeta },
      });
      //CRAIG automatic rollback here? if so that's cool
      const freqAnalysis = data.createFreqAnalysis;

      // change to promiseAll.all
      // const tester = await Promise.all(
      //   filteredProximityStationsId.map((stationID) => {
      //     return API.graphql(
      //       graphqlOperation(createFreqAnalysisStations, {
      //         input: {
      //           freqAnalysisId: freqAnalysis.id,
      //           stationStationId: stationID,
      //         },
      //       })
      //     );
      //   })
      // );

      return { [freqAnalysisMeta.assetId]: freqAnalysis };
    } catch (error) {
      console.log("Error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchFrequencyAnalysis = createAsyncThunk(
  "assets/FetchFrequencyAnalysisByAssetId",
  async (input, thunkAPI) => {
    //CRAIG IF IT EXISTS DON:T FETCH IT
    try {
      const { data } = await client.graphql({
        query: freqAnalysesByAssetId,
        variables: {
          assetId: input,
        },
      });

      return { [input]: data.freqAnalysesByAssetId.items };
    } catch (error) {
      console.log("Error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const AssetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setSelectedAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
    setHoveredAsset: (state, action) => {
      state.hoveredAsset = action.payload;
    },
    setSelectedAssetProximity: (state, action) => {
      state.selectedAssetProximity = action.payload;
    },
    setProximityStations: (state, action) => {
      state.proximityStations = action.payload;
    },
    setStationYrlyCountFilter: (state, action) => {
      state.stationYrlyCountFilter = action.payload;
    },
    setStationYearRange: (state, action) => {
      state.stationYearRange = action.payload;
    },
    setFilteredStationIds: (state, action) => {
      state.filteredProximityStationIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postProcessedModel.pending, (state, action) => {})
      .addCase(postProcessedModel.fulfilled, (state, action) => {
        console.log("added new processedModel");
      })
      .addCase(postProcessedModel.rejected, (state, action) => {})
      .addCase(fetchAssetById.pending, (state, action) => {})
      .addCase(fetchAssetById.fulfilled, (state, action) => {
        if (!state.dataGroups[action.payload.assetGroupId]) {
          state.dataGroups[action.payload.assetGroupId] = [action.payload];
        }
      })
      .addCase(fetchAssetById.rejected, (state, action) => {})
      .addCase(editAsset.pending, (state, action) => {
        // state.dataGroups[action.payload.assetGroupId] = state.dataGroups[action.payload.assetGroupId].map(asset => {
        //   if(asset.id === action.payload.id) {
        //     return action.payload
        //   }
        //   return asset
        // })
      })
      .addCase(editAsset.fulfilled, (state, action) => {
        // Check if the asset group exists and has items; if so, proceed with mapping
        state.dataGroups[action.payload.assetGroupId] = state.dataGroups[
          action.payload.assetGroupId
        ]?.length
          ? state.dataGroups[action.payload.assetGroupId].map((asset) => {
              if (asset.id === action.payload.id) {
                return action.payload;
              }
              return asset;
            })
          : state.dataGroups[action.payload.assetGroupId]; // Skip mapping if no items
      })
      .addCase(editAsset.rejected, (state, action) => {})
      .addCase(fetchAssetsByGroupId.pending, (state) => {
        // state.loading = "pending";
        // state.error = null;
      })
      .addCase(fetchAssetsByGroupId.fulfilled, (state, action) => {
        state.loading = "success";
        state.dataGroups[action.payload.assetGroupId] = action.payload.assets;
      })
      .addCase(fetchAssetsByGroupId.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAssets.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.loading = "success";
        state.data = action.payload;
      })
      .addCase(fetchAssets.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(postAsset.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(postAsset.fulfilled, (state, action) => {
        state.loading = "success";
        state.dataGroups[action.payload.assetGroupId].push(
          action.payload.asset
        );
      })
      .addCase(postAsset.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteAsset.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        state.loading = "success";

        state.dataGroups[action.payload.assetGroupId] = state.dataGroups[
          action.payload.assetGroupId
        ].filter((asset) => asset.id !== action.payload.asset.id);
      })
      .addCase(deleteAsset.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(postFreqAnalysis.pending, (state, action) => {})
      .addCase(postFreqAnalysis.fulfilled, (state, action) => {
        const [assetId] = Object.keys(action.payload);
        const [values] = Object.values(action.payload);
        state.freqAnalyses[assetId].push(values);
      })
      .addCase(postFreqAnalysis.rejected, (state, action) => {
        console.log("FAIL:ED");
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchFrequencyAnalysis.pending, (state, action) => {})
      .addCase(fetchFrequencyAnalysis.fulfilled, (state, action) => {
        state.freqAnalyses = { ...state.freqAnalyses, ...action.payload };
      })
      .addCase(fetchFrequencyAnalysis.rejected, (state, action) => {
        console.log("FAIL:ED");
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setSelectedAsset,
  setHoveredAsset,
  setSelectedAssetProximity,
  setProximityStations,
  setStationYrlyCountFilter,
  setStationYearRange,
  setFilteredStationIds,
} = AssetsSlice.actions; // for non async
export default AssetsSlice.reducer;
